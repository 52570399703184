<template>
   <ObiText
      as="table"
      v-bind="attrs"
      v-on="$listeners"
      class="obi-table table"
      :class="[
         hover && 'table-hover',
         color && `table-${color}`,
         striped && 'table-striped',
         bordered && 'table-bordered',
         borderless && 'table-borderless',
      ]"
   >
      <ObiText as="thead">
         <ObiText as="tr">
            <ObiText
               as="th"
               :key="index"
               v-html="col.text"
               v-for="(col, index) in headers"
               v-bind="getColumnAttributes(col)"
            />
         </ObiText>
      </ObiText>
      <ObiText as="tbody">
         <ObiText
            as="tr"
            v-for="(row, index) in data"
            :key="index"
            @click="$emit('rowClick', row)"
            @dblclick="$emit('rowDblClick', row)"
         >
            <ObiText as="td" v-for="(col, index) in headers" :key="index" v-bind="getCellAttributes(col)">
               <slot :name="col.value" v-bind:prop="row.get(col.value) || row[col.value]" v-bind:item="row">
                  <ObiText v-html="row.get(col.value) || row[col.value]" v-if="row.get(col.value) || row[col.value]" />
               </slot>
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'ObiTable',
   props: {
      options: {
         type: Object,
         default: () => {},
      },
      headers: {
         type: Array,
         default: () => [],
      },
      data: {
         type: Array,
         default: () => [],
      },
      hover: {
         type: Boolean,
         default: false,
      },
      striped: {
         type: Boolean,
         default: true,
      },
      bordered: {
         type: Boolean,
         default: false,
      },
      borderless: {
         type: Boolean,
         default: true,
      },
      color: {
         type: String,
         default: null,
      },
      isLoading: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
   methods: {
      getColumnAttributes(col) {
         return omit(col, ['text', 'value', 'cell']);
      },
      getCellAttributes(col) {
         return col.cell || this.getColumnAttributes(col);
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-table {
   margin-bottom: 0;
   thead th {
      position: sticky;
      top: 0;
      z-index: 1;
   }
}
</style>
